import React from 'react';
import InvoiceUploadForm from './InvoiceUploadForm'

export default function App() {
    return (
        <main role="main">
            <h1>Fulfillment Invoice Report</h1>
            <InvoiceUploadForm />
        </main>
    )
}
