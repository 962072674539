import React from 'react'
import { fetchSignedS3URL, extractContentsFromFile, uploadContentToURL } from './invoice-utils'

export default class InvoiceUploadForm extends React.Component {
    static defaultProps = {
        id: `invoice-upload-form-${Math.floor(Math.random() * 1000000)}`,
    }

    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    render() {
        return (
            <form id={this.props.id} onSubmit={this.handleSubmit}>
                <h2>Upload Invoice</h2>
                <p>Upload monthly <strong>The Occasions Group Summary Billing</strong> files to this form.
                   An email will be sent when the report is done being generated.</p>
                <p>This form will only except .XLS files.</p>
                <label htmlFor={this.props.id + '-invoice'}>File</label>
                <input id={this.props.id + '-invoice'} type="file" name="invoice" />
                <button type="submit">Upload</button>
            </form>
        )
    }

    /**
     * Handle form submissions and upload the submitted file to S3.
     *
     * @param Event event
     * @return void
     */
    handleSubmit(event) {
        console.debug('Upload form submitted', event)

        event.preventDefault()

        let signedUrl

        const nativeEvent = event.nativeEvent
        const clearForm = () => nativeEvent.target.reset()
        const submitButton = nativeEvent.target.querySelector('button[type="submit"]')
        const disableSubmit = () => submitButton.disabled = true
        const enableSubmit = () => submitButton.disabled = false
        const fetchSignedUrl = () => fetchSignedS3URL(process.env.REACT_APP_INVOICE_UPLOAD_ENDPOINT)
            .then(url => signedUrl = url)
        const uploadContentToSignedURL = content => uploadContentToURL(content, signedUrl)
        const showSuccessMessage = msg => () => showMessage(msg, nativeEvent.target, 'info')
        const showErrorMessage = msg => error => {
            showMessage(msg, nativeEvent.target, 'error')
            console.debug(msg, error)
        }

        disableSubmit()

        const file = getFileFromForm(nativeEvent.target)
        if (!validFileType(file, 'xls', 'application/vnd.ms-excel')) {
            showMessage('Invalid file type!', nativeEvent.target, 'error')
            clearForm()
            return true
        }

        fetchSignedUrl()
            .then(() => file)
            .then(extractContentsFromFile)
            .then(uploadContentToSignedURL)
            .then(showSuccessMessage('File Uploaded!'))
            .catch(showErrorMessage('Upload Failed!'))
            .finally(clearForm)
            .finally(enableSubmit)
    }
}

/**
 * Extract file information from the given form.
 *
 * @param Element form
 * @return File
 */
function getFileFromForm(form) {
    const fileInput = form.querySelector('input[name="invoice"]')
    const file = fileInput ? fileInput.files[0] : null

    if (!file) {
        throw new Error('File input not found')
    }

    return file
}

/**
 * Check if the given file matches the given type information.
 *
 * @param File file
 * @param String extension
 * @param String|RegEx type The mime type of the file.
 * @return File
 */
function validFileType(file, extension, type) {
    return (file.name.match(/.xls$/) && file.type.match(type))
}

/**
 * Adds a message to the target Element which then removes itself
 * automatically.
 *
 * @param String content
 * @param Element target
 * @param String type
 * @return void
 */
function showMessage(content, target, type) {
    const message = document.createElement('p')
    message.innerText = content
    message.setAttribute('role', 'alert')
    message.classList.add('message', `message-${type}`)

    // Add message to target
    target.insertBefore(message, target.firstChild)

    // Remove the message automatically
    setTimeout(() => message.remove(), 10000)
}
