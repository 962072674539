/**
 * Get a signed S3 URL from the given API URL.
 *
 * @param String url
 * @return Promise<String>
 */
export function fetchSignedS3URL(url) {
    console.debug('Fetching Signed S3 URL', url)

    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()

        xhr.addEventListener('loadend', event => {
            if (xhr.status >= 300) {
                const msg = 'Failed to retrieve S3 URL'
                reject(new Error(`${msg}: ${xhr.status} ${xhr.statusText}`))
                console.error(msg, event, xhr)
                return
            }

            resolve(JSON.parse(xhr.response).uploadUrl)
        })

        xhr.addEventListener('abort', event => reject(new Error('Invoice API request aborted')))
        xhr.addEventListener('error', event => reject(xhr.error))
        xhr.open('GET', url, true)
        xhr.send()
    })
}

/**
 * Get content from the uploaded local file.
 *
 * @param File file
 * @return Promise<Blob>
 */
export function extractContentsFromFile(file) {
    console.debug('Extracting content Blob from file', file)

    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()

        fileReader.addEventListener('loadend', event => {
            console.debug('Local file read', event, fileReader.result);
            resolve(new Blob([new Uint8Array(fileReader.result)]));
        })
        fileReader.addEventListener('abort', event => reject(new Error('File read aborted')))
        fileReader.addEventListener('error', event => reject(fileReader.error))
        fileReader.readAsArrayBuffer(file)
    })
}

/**
 * Upload the given content to the given URL.
 *
 * @param Blob content
 * @param String url
 * @return Promise
 */
export function uploadContentToURL(content, url) {
    console.debug('Uploading content to URL', content, url)

    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()

        xhr.addEventListener('loadend', event => {
            if (xhr.status >= 300) {
                const msg = 'S3 file upload failed'
                reject(new Error(`${msg}: ${xhr.status} ${xhr.statusText}`))
                console.error(msg, event, xhr)
                return
            }

            resolve()
        })
        xhr.addEventListener('abort', event => reject(new Error('File upload to S3 aborted')))
        xhr.addEventListener('error', event => reject(xhr.error))
        xhr.open('PUT', url, true)
        xhr.send(content)
    })
}
